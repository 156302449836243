var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ewqE1d7ngpC2XsdmN0ieq"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

console.log("process.env.SENTRY_DSN", process.env.NEXT_PUBLIC_SENTRY_DSN, process.env.NEXT_PUBLIC_NODE_ENV, process.env. NEXT_PUBLIC_SENTRY_DSN || "http://d4f7c6020c45cdac3d4ad6184a9de5ba@sentry.suzukibusinesscloud-dev.com/5")
	const isSentryEnabled = ["development",'ROMANIA-UAT','HUNGARY-UAT','INDONESIA-UAT','VIETNAM-UAT','PHILIPPINES-UAT','MEXICO-UAT','MEXICO-PROD','ROMANIA-PROD','VIETNAM-PROD','HUNGARY-PROD','INDONESIA-PROD','PHILIPPINES-PROD'].includes(process.env.NEXT_PUBLIC_NODE_ENV);

if (isSentryEnabled) {
Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || 'https://9189f92ba47516ed2c9e25eb5a02c2a2@o4508651608408064.ingest.de.sentry.io/4508651698651216',
  environment: process.env.NEXT_PUBLIC_NODE_ENV || "development",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.05,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  beforeSend(event) {
    if (event.request?.url?.includes("apple-touch-icon")) {
     return null; // Ignore these errors
       }
      return event;
   },

});
}